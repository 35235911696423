import React, { useEffect, useState } from "react";
import logo from "../assets/images/konas_logo.svg";
import { axiosInstance } from "../utils/axios";
import axios, { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  decodeToken,
  isAuthenticatedEvent,
  setAuthTokenEvent,
} from "../utils/auth";
import { useSnackbar } from "notistack";

interface ErrorResponse {
  success: false;
  result: null;
  error: {
    message: {
      error: string;
      error_description: string;
      statusCode: number;
      body: string;
      headers: Record<string, string>;
      name: string;
    };
  };
}

interface OTPTimerProps {
  initialExpiryTime: number;
  onTimeout: () => void;
}

const Timer = ({ onTimeout }: OTPTimerProps) => {
  const initialExpiryTime = localStorage.getItem("otpExpiryTime");
  const [expiryTime, setExpiryTime] = useState(
    initialExpiryTime ? Number(initialExpiryTime) : 180
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setExpiryTime((prevExpiryTime) => {
        if (prevExpiryTime > 0) {
          return prevExpiryTime - 1;
        } else {
          clearInterval(timer);
          onTimeout();
          return prevExpiryTime;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timer);
      localStorage.removeItem("otpExpiryTime");
    };
  }, [onTimeout]);

  useEffect(() => {
    localStorage.setItem("otpExpiryTime", String(expiryTime));
  }, [expiryTime]);

  const formattedTime = `${Math.floor(expiryTime / 60)
    .toString()
    .padStart(2, "0")}:${(expiryTime % 60).toString().padStart(2, "0")}`;

  return <div className="text-center">Time Remaining: {formattedTime}</div>;
};

const OTPEvent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const email = location.state?.email;
  const [code, setCode] = useState("");
  const [isTimer, setIsTimer] = useState(true);

  const handleChangeOTP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const payload = {
        email: email,
        code: code,
      };

      const response = await axiosInstance.post("/auth/verify", payload);
      if (
        decodeToken(response.data.result.data.id_token).event ||
        decodeToken(response.data.result.data.id_token).booth
      ) {
        setAuthTokenEvent(response.data.result.data.id_token);
        enqueueSnackbar(`${response.data.result.message}`, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        navigate("/event");
      } else {
        enqueueSnackbar("Not authenticated as admin event or booth", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const errorResponse = axiosError.response.data as ErrorResponse;
        const errorMessage = errorResponse.error.message;
        enqueueSnackbar(`${errorMessage.error_description}`, {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await axiosInstance.post("/auth/login", {
        email: email,
      });
      enqueueSnackbar("OTP telah dikirimkan ulang ke email", {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setIsTimer(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar("OTP gagal dikirimkan", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    }
  };

  const handleTimeout = () => {
    setIsTimer(false);
  };

  useEffect(() => {
    if (isAuthenticatedEvent()) {
      navigate("/event");
    }
  });

  return (
    <div className="flex justify-center m-auto h-[100vh]">
      <div className="flex flex-col xl:w-[30%] m-auto max-w-[400px] h-[70%] rounded-md p-[40px] sm:shadow-3xl">
        <img src={logo} alt="logo" className="w-[100px] mx-auto" />
        <h1 className="text-[22px] text-center mt-4">Verifikasi Email</h1>
        <p className="my-4 text-center">
          OTP hanya dikirimkan untuk email yang sudah didaftarkan. Mohon cek
          spam.
        </p>
        <form>
          <input
            type="Kode OTP"
            id="OTP"
            name="OTP"
            value={code}
            onChange={handleChangeOTP}
            className="mt-1 py-2 px-4 my-1 border-[1px] border-[#BABDCC] rounded-md w-full bg-white"
            placeholder="XXXXXX"
          />
          {isTimer && (
            <Timer initialExpiryTime={180} onTimeout={handleTimeout} />
          )}
          {isTimer ? (
            <button
              disabled={code.length === 0}
              onClick={handleSubmit}
              className={`flex px-[40px] py-[8px] my-4 w-full m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
                code.length > 0 ? "bg-primary-100" : "bg-disabled"
              }`}
            >
              Lanjut
            </button>
          ) : (
            <button
              onClick={handleResendOTP}
              className="flex px-[40px] py-[8px] my-4 w-full m-auto rounded-[30px] items-center justify-center shadow-3xl text-white bg-primary-100"
            >
              Resend OTP
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default OTPEvent;
