import LoginEvent from "../pages/LoginEvent";
import OTPEvent from "../pages/OTPEvent";
import Events from "../pages/Events";
import Scan from "../pages/Scan";

interface PageRouting {
  title: string;
  path: string;
  component: () => JSX.Element;
}

export const routes: PageRouting[] = [
  { title: "Log In", path: "/", component: LoginEvent },
  { title: "OTP", path: "/otp", component: OTPEvent },
];

export const adminRoutes: PageRouting[] = [
  {
    title: "Scan QR Event",
    path: "/event",
    component: Events,
  },
  {
    title: "Scan QR Event",
    path: "/scan",
    component: Scan,
  },
];
