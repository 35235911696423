import { useEffect, useState } from "react";
import { axiosInstanceEvent } from "../utils/axios";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { logoutEvent } from "../utils/auth";
import konasLogo from "../assets/images/konas_logo2.svg";
import searchIcon from "../assets/images/search.svg";
import personIcon from "../assets/images/person.svg";
import closeIcon from "../assets/images/close_round.svg";
import { FiMenu } from "react-icons/fi";

export interface Event {
  id: string;
  name: string;
  allowRepeatingScan: boolean;
  showCustomerDetails: boolean;
  allowedCategory: number[];
  allowedWorkshops: number[];
}

const Events = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleOptionClick = (option: Event) => {
    setSelectedId(option.id);
    setSelectedEvent(option);
  };

  const handleNextClick = () => {
    navigate("/scan", { state: { selectedEvent } });
  };

  useEffect(() => {
    const getEvents = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstanceEvent("/event");
        setEvents(response.data.result.events);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response && axiosError.response.status === 401) {
            enqueueSnackbar("Mohon log in kembali", {
              autoHideDuration: 3000,
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            navigate("/");
            logoutEvent();
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    getEvents();
  }, []);

  const filteredData = events.filter((item) => {
    const itemName = item.name.toLowerCase();
    return itemName.includes(searchText.toLowerCase());
  });

  return (
    <div className="flex flex-col h-screen">
      <div className="sticky top-0 bg-gray-200 p-4">
        <div className="flex justify-between items-center mb-4">
          <img src={konasLogo} alt="logo" />
          <button onClick={() => setIsOpen(!isOpen)}>
            <FiMenu className="text-[16px]" />
          </button>
        </div>
        <div>
          <div className="relative">
            <input
              type="text"
              className="mt-1 py-2 pl-10 rounded-2xl w-full bg-[#EBEBEB] border-[#BABDCC] text-gray-500"
              placeholder="Cari Acara"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div
              className="absolute inset-y-0 left-0 pl-3  
                flex items-center justify-center
                pointer-events-none"
            >
              <FiSearch />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        <p className="font-semibold my-4">Pilih Acara</p>
        <div className="flex flex-col justify-center gap-4 items-center">
          {!isLoading ? (
            filteredData.map((event) => (
              <button
                key={event.id}
                className={`w-full py-[20px] px-[16px] lg:px-[27px] rounded-[20px] text-[14px] lg:text-base shadow-3xl ${
                  selectedId === event.id ? "border-primary-50 border-2" : ""
                }`}
                onClick={() => handleOptionClick(event)}
              >
                {event.name}
              </button>
            ))
          ) : (
            <div className="flex items-center justify-center">Loading...</div>
          )}
        </div>
      </div>
      <div className="sticky bottom-0 p-4 border-t-2 border-[#EBEBEB] mt-2 ">
        {isOpen ? (
          <div>
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-10"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div
                className={`${
                  isOpen ? "translate-y-0" : "translate-y-full"
                } fixed bottom-0 left-0 right-0 rounded-t-[10px] bg-white transform transition-transform duration-500`}
              >
                <ul className="flex flex-col bg-white my-8 ">
                  <li className="flex justify-end px-4">
                    <img src={closeIcon} alt="close" />
                  </li>
                  <li className="flex justify-between px-4 pb-4 font-bold">
                    Menu
                  </li>
                  <li className="flex gap-[10px] p-4 hover:bg-[#F5F1F1] text-gray cursor-pointer">
                    <img src={searchIcon} alt="search" />
                    Select Event
                  </li>
                  <li
                    className="flex p-4 gap-[10px] hover:bg-[#F5F1F1] text-gray cursor-pointer"
                    onClick={() => {
                      navigate("/");
                      logoutEvent();
                    }}
                  >
                    <img src={personIcon} alt="search" />
                    Log Out
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <button
            className={`w-full py-[12px] px-[27px] rounded-[30px] my-2 shadow-3xl text-white ${
              selectedId ? "bg-primary-100" : "bg-primary-50"
            }`}
            disabled={!selectedId}
            onClick={() => handleNextClick()}
          >
            Lanjut
          </button>
        )}
      </div>
    </div>
  );
};

export default Events;
