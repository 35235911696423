import { useEffect, useState, useRef } from "react";
import { axiosInstanceEvent } from "../utils/axios";
import { useLocation } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import Scanner from "../components/Scanner";
import UserInformation from "../components/UserInformation";
import { IUser } from "../components/UserInformation";
import Result from "../components/Result";
import konasLogo from "../assets/images/konas_logo2.svg";
import { FiMenu } from "react-icons/fi";
import searchIcon from "../assets/images/search.svg";
import personIcon from "../assets/images/person.svg";
import closeIcon from "../assets/images/close_round.svg";
import { useNavigate } from "react-router-dom";
import { logoutEvent } from "../utils/auth";
import axios, { AxiosError } from "axios";

interface Event {
  id: string;
  name: string;
  allowRepeatingScan: boolean;
  showCustomerDetails: boolean;
  allowedCategory: number[];
  allowedWorkshops: number[];
}

interface ErrorMessage {
  message: string;
}

const Scan = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [user, setUser] = useState<IUser>({
    id: "",
    name: "",
    email: "",
    categoryId: 0,
    phone: "",
    NPAPerdoski: "",
    NPAIDI: "",
    cabang: "",
    fileSTR: "",
    workshops: [],
    nik: "",
  });
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const selectedEvent = location.state?.selectedEvent as Event;

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (userId !== "") {
          // console.log(userId.split(":")[1]);
          setStep(6);
          const response = await axiosInstanceEvent.get(
            `/admin/users/${userId.split(":")[1]}`
          );
          // console.log(response);
          if (response.data.result) {
            setCategoryId(response.data.result.categoryId);
            setUser(response.data.result);

            const payload = {
              pesertaId: userId.split(":")[1],
              eventId: selectedEvent.id,
            };
            const responseLog = await axiosInstanceEvent.post(
              "/event/log",
              payload
            );
            // console.log(
            //   response.data.result.workshops.some((element: number) =>
            //     selectedEvent.allowedWorkshops.includes(element)
            //   ) || selectedEvent.allowedWorkshops.length === 0
            // );
            if (
              (selectedEvent.allowedCategory.includes(
                response.data.result.categoryId
              ) ||
                selectedEvent.allowedCategory.length === 0) &&
              (response.data.result.workshops.some((element: number) =>
                selectedEvent.allowedWorkshops.includes(element)
              ) ||
                selectedEvent.allowedWorkshops.length === 0)
            ) {
              // console.log("masuk 1");
              setStep(2); // berhasil
              if (selectedEvent.showCustomerDetails) {
                // console.log("masuk 2");

                setTimeout(() => {
                  setStep(4); // show detail
                }, 3000);
              } else {
                // console.log("masuk 3");

                setTimeout(() => {
                  setUserId("");
                  setStep(1); // scanner
                }, 3000);
              }
              // console.log("tes");
            } else {
              // console.log("masuk 4");

              setMessage(
                "QR Peserta tidak valid atau tidak terdaftar.\nRedirect dalam 1 detik"
              );
              setStep(3); // QR Peserta tidak valid atau tidak terdaftar
              setTimeout(() => {
                setUserId("");
                setStep(1); // scanner
              }, 3000);
            }
          } else {
            // console.log("masuk 5");

            setMessage("ID Peserta tidak terdaftar! Redirect dalam 1 detik");
            setStep(3);
            setTimeout(() => {
              setUserId("");
              setStep(1); // scanner
            }, 3000);
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          const errorMessage = axiosError.response?.data as ErrorMessage;
          // console.log("masuk 6");

          setMessage(errorMessage.message);
          if (
            errorMessage.message === "Peserta already scanned for this event"
          ) {
            setMessage(
              "QR Peserta sudah di scan lebih dari 1 kali.\nRedirect dalam 1 detik"
            );
          }

          setStep(5); // error message

          setTimeout(() => {
            setUserId("");
            setStep(1); // scanner
          }, 3000);
        }
      } finally {
      }
    };

    const insertLog = async () => {
      try {
        if (userId !== "") {
          const payload = {
            pesertaId: userId.split(":")[1],
            eventId: selectedEvent.id,
          };
          const response = await axiosInstanceEvent.post("/event/log", payload);
        }
      } catch (error) {
      } finally {
      }
    };
    getUserData();
    // insertLog();
  }, [userId]);

  const handleResult = (text: string) => {
    setUserId(text);
  };

  const handleNextClick = () => {
    if (step === 2) {
      if (selectedEvent.showCustomerDetails) {
        setStep(4);
      } else {
        setStep(1);
        setUserId("");
      }
    } else {
      setStep(1);
      setUserId("");
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="sticky top-0 bg-gray-200 p-4">
        <div className="flex justify-between items-center mb-4">
          <img src={konasLogo} alt="logo" />
          <button onClick={() => setIsOpen(!isOpen)}>
            <FiMenu className="text-[16px]" />
          </button>
        </div>
      </div>
      <div className="m-auto h-fit w-fit">
        {step === 1 && <Scanner onScan={handleResult} />}
        {step === 2 && (
          <div className="p-8">
            <Result
              type={"success"}
              title={"Scan QR Peserta Berhasil!"}
              message={"Redirect dalam 1 detik"}
            />
          </div>
        )}
        {step === 3 && (
          <Result
            type={"failure"}
            title={"Scan QR Peserta Gagal!"}
            message={message}
          />
        )}
        {step === 5 && (
          <Result
            type={"scanned"}
            title={"Scan QR Peserta Gagal!"}
            message={message}
          />
        )}
        {step === 4 && (
          <p>
            <UserInformation anggota={user} />
          </p>
        )}
        {step === 6 && <p>Loading...</p>}
      </div>
      {step !== 1 && (
        <div className="sticky bottom-0 p-4 border-t-2 border-[#EBEBEB] mt-2 ">
          <button
            className={`w-full py-[12px] px-[27px] rounded-[30px] my-2 shadow-3xl text-white  bg-primary-100 `}
            onClick={() => handleNextClick()}
          >
            Lanjut
          </button>
        </div>
      )}
      {isOpen && (
        <div>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <div
              className={`${
                isOpen ? "translate-y-0" : "translate-y-full"
              } fixed bottom-0 left-0 right-0 rounded-t-[10px] bg-white transform transition-transform duration-500`}
            >
              <ul className="flex flex-col bg-white my-8 ">
                <li className="flex justify-end px-4">
                  <img src={closeIcon} alt="close" />
                </li>
                <li className="flex justify-between px-4 pb-4 font-bold">
                  Menu
                </li>
                <li
                  className="flex gap-[10px] p-4 hover:bg-[#F5F1F1] text-gray cursor-pointer"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src={searchIcon} alt="search" />
                  Select Event
                </li>
                <li
                  className="flex p-4 gap-[10px] hover:bg-[#F5F1F1] text-gray cursor-pointer"
                  onClick={() => {
                    navigate("/");
                    logoutEvent();
                  }}
                >
                  <img src={personIcon} alt="search" />
                  Log Out
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Scan;
