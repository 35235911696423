import { useEffect, useState } from "react";
import { axiosInstanceEvent } from "../utils/axios";
import personIllustration from "../assets/images/person_illustration.svg";

export interface IUser {
  id: string;
  name: string;
  email: string;
  categoryId: number;
  phone: string;
  NPAPerdoski: string;
  NPAIDI: string;
  cabang: string;
  fileSTR: string;
  workshops: (string | number)[];
  nik: string;
}

interface IWorkshop {
  id: number;
  name: string;
  price: number;
}

interface Props {
  anggota: IUser;
}

const UserInformation = ({ anggota }: Props) => {
  const [workshopArray, setWorkshopArray] = useState<IWorkshop[]>([]);
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [pendamping, setPendamping] = useState<IWorkshop[]>([]);
  const [acara, setAcara] = useState<IWorkshop[]>([]);

  const isAnggota = anggota.categoryId === 1 || anggota.categoryId === 2;

  const getListWorkshop = (arr: number[]) => {
    const sortedArray = arr.sort((a, b) => a - b);
    const dataMap = new Map(workshopArray.map((item) => [item.id, item]));
    const resultString = sortedArray
      .map((id) => dataMap.get(id)?.name)
      .filter((name) => name !== undefined)
      .join(", ");

    return resultString;
  };

  useEffect(() => {
    const getWorkshopsData = async () => {
      try {
        if (anggota.categoryId) {
          const response = await axiosInstanceEvent.get(
            `/products/${anggota.categoryId}`
          );
          setWorkshops(response.data.result.workshops);
          setAcara(response.data.result.acara);
          setPendamping(response.data.result.pendamping);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getWorkshopsData();
  }, []);

  useEffect(() => {
    if (acara && workshops && pendamping) {
      setWorkshopArray([...workshops, ...pendamping]);
    }
  }, [acara, workshops, pendamping]);

  const getCategory = (id: number) => {
    if (id === 1) {
      return "Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)";
    } else if (id === 2) {
      return "Anggota Muda Perdoski (Residen)";
    } else if (id === 3) {
      return "Dokter Spesialis Lain";
    } else if (id === 4) {
      return "Dokter Umum";
    } else {
      return "";
    }
  };

  return (
    <div className="p-6 rounded-[20px] min-w-[328px] w-[90%] shadow-3xl m-auto bg-white">
      <ul className="space-y-3">
        <li className="flex justify-between">
          <span className="flex items-center">
            <img src={personIllustration} alt="person" />
            <span className="ml-2 font-bold">{anggota.name}</span>
          </span>
        </li>
        <li>
          <span className="text-[#828282] font-medium mr-2">No Hp </span>
          {anggota.phone}
        </li>
        <li>
          <span className="text-[#828282] font-medium mr-2">Email </span>
          {anggota.email}
        </li>
        <li>
          <span className="text-[#828282] font-medium mr-2">Kategori </span>
          {getCategory(anggota.categoryId)}
        </li>
        <li>
          <span className="text-[#828282] font-medium mr-2">
            NPA {isAnggota ? "Perdoski" : "IDI"}
          </span>
          {anggota.NPAPerdoski || anggota.NPAIDI}
        </li>
        <li>
          <span className="text-[#828282] font-medium mr-2">NIK</span>
          {anggota.nik || "-"}
        </li>
        {isAnggota && (
          <li>
            <span className="text-[#828282] font-medium mr-2">Asal PC </span>
            {anggota.cabang}
          </li>
        )}

        <li>
          <span className="text-[#828282] font-medium mr-2">Acara </span>
          Simposium{anggota.workshops.includes(1) ? ", Pra-Konas" : ""}
        </li>
        <li>
          <span className="text-[#828282] font-medium mr-2">Workshop </span>
          {getListWorkshop(anggota.workshops as number[]) || "-"}
        </li>
      </ul>
    </div>
  );
};

export default UserInformation;
