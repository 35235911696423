import { jwtDecode } from "jwt-decode";

type Payload = {
  aud: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nickname: string;
  picture: string;
  role: string;
  sub: string;
  updated_at: string;
  election: boolean;
  event: boolean;
  booth: boolean;
};

function decodeToken(token: string) {
  return jwtDecode(token) as Payload;
}

function setAuthTokenEvent(token: string) {
  localStorage.setItem("tokenEvent", token);
}

function decodeJwtEvent() {
  const decoded: Payload = jwtDecode(
    localStorage.getItem("tokenEvent") as string
  );
  return decoded;
}

function logoutEvent() {
  localStorage.removeItem("tokenEvent");
}

function isAuthenticatedEvent() {
  return localStorage.getItem("tokenEvent") !== null;
}

export {
  setAuthTokenEvent,
  decodeJwtEvent,
  logoutEvent,
  isAuthenticatedEvent,
  decodeToken,
};
