import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { routes, adminRoutes } from "./routing";
import { isAuthenticatedEvent } from "../utils/auth";

interface AdminRouteProps {
  getIsAdmin: () => boolean;
}

const AdminRoutes = ({ getIsAdmin }: AdminRouteProps) => {
  return getIsAdmin() ? <Outlet /> : <Navigate to="/404" />;
};

export const Navigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          const Component = route.component;
          return (
            <Route key={index} path={route.path} element={<Component />} />
          );
        })}

        <Route element={<AdminRoutes getIsAdmin={isAuthenticatedEvent} />}>
          {adminRoutes.map((route, index) => {
            const Component = route.component;
            return (
              <Route key={index} path={route.path} element={<Component />} />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
