import LottieAnimation from "./LottieAnimation";

interface Props {
  title: string;
  message: string;
  type: string;
}

const Result = ({ title, type, message }: Props) => {
  return (
    <div className="flex flex-col gap-[40px] items-center justify-center">
      <p className="font-bold text-[20px]">{title}!</p>
      <div className="w-[280px] m-auto">
        <LottieAnimation status={() => type} />
      </div>
      <p className="break-words whitespace-pre-wrap text-[14px] text-center whitespace-nowrap">
        {message}
      </p>
    </div>
  );
};

export default Result;
