import React from "react";
import Lottie from "react-lottie";
import animationSuccess from "../assets/animations/success.json";
import animationFailure from "../assets/animations/failure.json";
import animationScanned from "../assets/animations/scanned.json";

interface Props {
  status: () => string;
}

const LottieAnimation = ({ status }: Props) => {
  const getAnimation = () => {
    if (status() === "success") {
      return animationSuccess;
    } else if (status() === "scanned") {
      return animationScanned;
    } else {
      return animationFailure;
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: getAnimation(),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="w-1/2 mx-auto">
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default LottieAnimation;
